// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_archives-module--afterCaption--4ed5a";
export var columnWrapper = "maine_digest_archives-module--columnWrapper--aac77";
export var dateline = "maine_digest_archives-module--dateline--b4a8a";
export var heading = "maine_digest_archives-module--heading--d31da";
export var headingLogo = "maine_digest_archives-module--headingLogo--87cb4";
export var headingWrapper = "maine_digest_archives-module--headingWrapper--b817b";
export var heroImage = "maine_digest_archives-module--heroImage--5b3c4";
export var instance = "maine_digest_archives-module--instance--16704";
export var latestnewsarticleheadline = "maine_digest_archives-module--latestnewsarticleheadline--cf97c";
export var link = "maine_digest_archives-module--link--419c9";
export var mainWrapper = "maine_digest_archives-module--mainWrapper--f4f7d";
export var newsArchives = "maine_digest_archives-module--newsArchives--73f24";
export var photo = "maine_digest_archives-module--photo--35d97";
export var photoCaption = "maine_digest_archives-module--photoCaption--74774";
export var photosWrapper = "maine_digest_archives-module--photosWrapper--da4c4";
export var rightCaptions = "maine_digest_archives-module--rightCaptions--477a9";
export var rightPhoto = "maine_digest_archives-module--rightPhoto--b22c7";
export var rightPhotoCaption = "maine_digest_archives-module--rightPhotoCaption--1126c";
export var subheading = "maine_digest_archives-module--subheading--99d8b";
export var textWrapper = "maine_digest_archives-module--textWrapper--574f7";